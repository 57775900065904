const state = {
  fag: true,
  currency: {},
  token: '',
  userInfo: {},
};

const mutations = {
  // 首页播放按钮的控制
  SET_FAG: (state, fag) => {
    state.fag = fag;
  },

  // 选中的币种
  SET_CURRENCY: (state, payload) => {
    state.currency = payload;
  },

  // token
  SET_TOKEN: (state, payload) => {
    state.token = payload;
  },
  // info
  SET_INFO: (state, payload) => {
    state.userInfo = payload;
  }
};

const actions = {
  changSetFag({ commit }, fag){
    commit('SET_FAG',fag);
  },

  changSetCurrency({ commit }, payload){
    commit('SET_CURRENCY', payload);
  },

  changSetToken({ commit }, payload){
    commit('SET_TOKEN', payload);
  }
  ,

  changSetInfo({ commit }, payload){
    commit('SET_INFO', payload);
  }
};


export default {
  state,
  mutations,
  actions
};

