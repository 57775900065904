import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/styles/index.scss";


import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

// 无缝滚动广告
import Scroll from "vue-seamless-scroll";
// 视频播放
import Video from "video.js";
import "video.js/dist/video-js.css";
// 轮播图
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
// 图标
import "./icons";
import * as custom from "@/filters/index.js";
import { imgBaseURL, musicBaseURL } from "@/config";


// 全局引入  VueAwesomeSwiper
Vue.use(VueAwesomeSwiper, /* { default options with global component } */);
Vue.prototype.$video = Video;
Vue.use(ElementUI);
Vue.use(Scroll);
Vue.config.productionTip = false;

Object.keys(custom).forEach(key => {
  Vue.filter(key, custom[key]);
});
Vue.prototype.$imgBaseURL = imgBaseURL;
Vue.prototype.$musicBaseURL = musicBaseURL;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
