<template>
  <vue-seamless-scroll
    :data="listData"
    :class-option="optionSingleHeightTime"
    class="seamless-warp"
  >
    <ul class="item">
      <li v-for="item in listData" :key="item.id">
        <router-link
          :to="{
            path: '/travel-route/detail',
            query: { route_bn: item.route_bn }
          }"
        >
          <span class="title" v-text="item.ad_title"></span>
        </router-link>
      </li>
    </ul>
  </vue-seamless-scroll>
</template>
<script>
export default {
  name: "SeamlessScroll"
};
</script>
<style lang="scss" scoped>
.seamless-warp {
  height: 25px;
  width: 260px;
  overflow: hidden;
  font-size: 18px;
  color: rgba(102, 102, 102, 1);
  margin-top: 47px;

  ul {
    padding: 0;
    margin: 0 auto;
    li {
      height: 25px;
      line-height: 25px;
      font-size: 18px;
      overflow: hidden;
      word-break: keep-all;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
<script>
import { routeTagDetailSearch } from "@/api/index";

export default {
  data() {
    return {
      listData: [
        {
          disp_no: "10",
          id: "route_10000",
          title:
            "流动文字线路广告流动文字线路广告流动文字线路广告流动文字线路广告流动文字线路广告",
          url: "./views/travel_route"
        }
      ]
    };
  },
  computed: {
    optionSingleHeightTime() {
      return {
        singleHeight: 25,
        limitMoveNum: this.listData ? this.listData.length : 0 // 开始无缝滚动的数据量 this.dataList.length
      };
    }
  },
  created() {
    this.getRollAd();
  },
  methods: {
    // 首页-文字轮播
    getRollAd() {
      routeTagDetailSearch({ tagid: "tag_type_006" })
        .then(res => {
          this.listData = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
