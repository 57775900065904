const state = {
  custom: {},
  images: {}
};

const mutations = {
  SET_CUSTOM: (state, payload) => {
    state.custom = {...state.custom, ...payload};
  },
  CLEAR_CUSTOM: (state) => {
    state.custom = {};
  },
  SET_IMAGES: (state, payload) => {
    state.images = payload;
  },
};

const actions = {
  setCustom({ commit }, params){
    commit('SET_CUSTOM',params);
  },
  setImages({ commit }, params){
    commit('SET_IMAGES',params);
  },
  clearCustom({ commit }){
    commit('CLEAR_CUSTOM');
  }
};


export default {
  state,
  mutations,
  actions
};

