const state = {
  routeObj: {},
};

const mutations = {
  SET_ROUTEOBJ: (state, routeObj) => {
    state.routeObj = routeObj;
  }
};
const actions = {
  changSetRouteObj({commit},payload){
    commit('SET_ROUTEOBJ',payload);
  }
};


export default {
  state,
  mutations,
  actions
};

