<template>
  <div>
    <div class="body">
      <div class="main">
        <div class="mian-img">
          <router-link to="/">
            <el-image :src="require('@/assets/index/logo.png')"></el-image>
          </router-link>
        </div>
        <seamless-scroll class="seamless"></seamless-scroll>
        <div class="box-info">
          <router-link
            class="a-nav"
            v-if="home.token"
            :to="{ path: '/personal-center/index' }"
            >会员中心</router-link
          >
          <!-- <a href class="a-nav" v-if="home.token">我的订单</a> -->
          <router-link
            v-if="!home.token"
            class="a-nav"
            :to="{ path: '/login/login' }"
            >登录</router-link
          >
          <router-link
            v-if="!home.token"
            class="a-nav"
            :to="{ path: '/login/register' }"
            >注册</router-link
          >
          <a href="javascript:void(0);" class="a-nav" v-popover:popover1>
            公众号
            <i class="el-icon-arrow-down pl-2"></i>
          </a>
          <el-popover
            ref="popover1"
            placement="bottom"
            width="150"
            trigger="hover"
          >
            <el-image :src="headerInfo.e_wx_img"></el-image>
          </el-popover>
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              <a href="javascript:void(0);" class="a-nav">
                {{
                  command.currency_sign && command.currency_nm
                    ? `${command.currency_sign} ${command.currency_nm}`
                    : '币种选择'
                }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </a>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="item in yuanObj"
                :key="item.currency_id"
                :command="item"
                >{{ item.currency_sign
                }}{{ item.currency_nm }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <a href class="a-nav">联系电话：{{ headerInfo.e_tel }}</a>
          <button
            style="padding: 2px; border: none; background-color: transparent"
            class="ml-10"
            @click="fagTag"
          >
            <svg-icon
              v-if="fag"
              icon-class="bofang"
              style="font-size: 22px"
            ></svg-icon>
            <svg-icon
              v-else
              icon-class="zanting"
              style="font-size: 22px"
            ></svg-icon>
          </button>
          <span class="ml-5" style="padding: 0; border: none">
            <img
              v-if="fag"
              style="width: 15px; height: auto"
              :src="require('@/assets/index/ztyl.png')"
            />
            <img v-else :src="require('@/assets/index/bofang.gif')" />
          </span>
          <audio loop id="play"></audio>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { musicPlaySet, currency, getHeaderInfo } from '@/api/index';
import SeamlessScroll from './SeamlessScroll';
import { mapState, mapActions } from 'vuex';
export default {
  name: 'HeadTop',
  components: { SeamlessScroll },
  data() {
    return {
      songList: [],
      yuanObj: [],
      musicList: [],
      command: {},
      headerInfo: {},
    };
  },
  computed: {
    fag() {
      return this.$store.state.home.fag;
    },
    ...mapState(['home']),
  },
  created() {
    this.getCurrency();
    this.getMusicPlay();
    this.getHeaderInfoFunc();
  },
  methods: {
    ...mapActions(['changSetCurrency']),
    handleCommand(command) {
      this.command = command;
      this.changSetCurrency(command);
    },
    fagTag() {
      this.$store.dispatch('changSetFag', !this.fag);
      this.playBack();
    },
    playBack() {
      var play = document.getElementById('play');
      play.control = true;
      if (play.paused) {
        //如果已暂停则播放
        play.play(); //播放控制
      } else {
        // 已播放点击则暂停
        play.pause(); //暂停控制
      }
    },
    getHeaderInfoFunc() {
      getHeaderInfo()
        .then((res) => {
          this.headerInfo = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getMusicPlay() {
      musicPlaySet()
        .then((res) => {
          this.$nextTick(() => {
            var play = document.getElementById('play');
            play.src = `${this.$musicBaseURL}${res.fileanme}`;
            // 自动播放失败自动播放失败自动播放失败自动播放失败自动播放失败自动播放失败
            // this.fagTag()
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCurrency() {
      currency()
        .then((res) => {
          this.yuanObj = res.data;
          if (this.$store.state.home.currency.currency_id) {
            this.command = this.$store.state.home.currency;
          } else {
            this.command = this.yuanObj[1];
            this.changSetCurrency(this.yuanObj[1]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
.main-box-home .el-image {
  padding: 20px;
}
</style>

<style lang="scss" scoped>
.body {
  /*margin: 0 auto;*/
  display: flex;
  justify-content: center;

  .main {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    .mian-img {
      width: 120px;
      height: auto;
      :deep(.el-image) {
        padding: 20px;
      }
    }
  }

  .seamless {
    margin-right: 3%;
  }

  .box-info {
    font-size: 16px;
    color: rgba(102, 102, 102, 1);
    margin-top: 46px;

    a {
      padding: 0 16px;
      border-right: #dadada 1px solid;
    }
  }
}

.el-dropdown-link {
  cursor: pointer;
  /*color: #F6C382;*/
}
img {
  vertical-align: baseline;
}
.el-icon-arrow-down {
  font-size: 16px;
}
</style>