const state = {
  order: {}
};

const mutations = {
  SET_ORDER: (state, payload) => {
    state.order = {...state.order, ...payload};
  },
  CLEAR_ORDER: (state) => {
    state.order = {};
  },
};

const actions = {
  setOrder({ commit }, params){
    commit('SET_ORDER',params);
  },
  clearOrder({ commit }){
    commit('CLEAR_ORDER');
  },
};


export default {
  state,
  mutations,
  actions
};

