<template>
    <div class="main-box">
      <router-link class="box" :to="{path: '/about-info/index', query: {type: 1}}">
       <!-- <svg-icon style="font-size: 60px" icon-class="gongsi"></svg-icon> -->
        <el-image :src="require('@/assets/index/guanyuwomen.png')" alt=""></el-image>
        <div class="div1">关于我们</div>
      </router-link>
      <router-link class="box" :to="{path: '/about-info/index', query: {type: 2}}">
       <!-- <svg-icon style="font-size: 60px" icon-class="qiyelinian"></svg-icon> -->
        <el-image :src="require('@/assets/index/qiyelilian.png')" alt=""></el-image>
        <div class="div1">企业理念</div>
      </router-link>
      <router-link class="box" :to="{path: '/about-info/index', query: {type: 3}}">
       <!-- <svg-icon style="font-size: 60px" icon-class="lianxiwomen"></svg-icon> -->
        <el-image :src="require('@/assets/index/yinsitiaokuan.png')" alt=""></el-image>
        <div class="div1">隐私条款</div>
      </router-link>
      <router-link class="box" :to="{path: '/about-info/index', query: {type: 4}}">
       <!-- <svg-icon style="font-size: 60px" icon-class="fukuanfangshi"></svg-icon> -->
        <el-image :src="require('@/assets/index/fukuanfangshi.png')" alt=""></el-image>
        <div class="div1">付款方式</div>
      </router-link>
    </div>
</template>

<script>
  export default {
    name: "FootDown"
  };
</script>

<style lang="scss" scoped>
  .main-box{
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 60px 0;
    .box{
      height: 72px;
      /*border: #303133 2px solid;*/
      padding: 5px;
      border-radius: 4px;
      margin: 0 100px;
      .div1{
        margin-top: 5px;
        background:rgba(255,255,255,1);
      }
    }
  }
</style>
