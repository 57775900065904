import request from "@/utils/request";

// 获取 币种
export function baseDicCurrency() {
  return request({
    url: "/base_dic/currency",
    method: "get"
  });
}

// 获取 付款状态
export function baseDicPayStatus() {
  return request({
    url: "/base_dic/pay_status",
    method: "get"
  });
}

// 获取 团期类型
export function baseDicRegular() {
  return request({
    url: "/base_dic/regular",
    method: "get"
  });
}

// 获取 参团方式
export function baseDicJoinType() {
  return request({
    url: "/base_dic/join_type",
    method: "get"
  });
}

// 获取 服务语言
export function baseDicServiceLanguage() {
  return request({
    url: "/base_dic/service_language",
    method: "get"
  });
}

// 静态页类别检索
export function baseDicStaticPageType() {
  return request({
    url: `/base_dic/static_page_type`,
    method: "get"
  });
}

// 获取 车导车型
export function baseDicCarType() {
  return request({
    url: "/base_dic/car_type",
    method: "get"
  });
}

// 获取 标签类别
export function baseDicTagType() {
  return request({
    url: "/base_dic/tag_type",
    method: "get"
  });
}

// 获取首页频道
export function channels() {
  return request({
    url: "/home_page/channels",
    method: "get"
  });
}

// 获取客服信息
export function supportInfo() {
  return request({
    url: "/comm/support_info",
    method: "get"
  });
}

// 私密定制 - 反馈
export function feedbackInsert(data) {
  return request({
    url: "/feedback/insert",
    method: "post",
    data
  });
}
// 获取币种
export function currency() {
  return request({
    url: "/comm/currency",
    method: "get"
  });
}

// 私密定制 - 说明文字
export function cusWord() {
  return request({
    url: "/home_page/cus_word",
    method: "get"
  });
}

// 私密定制 - 表单
export function hpBlock(data) {
  return request({
    url: "/prv_customization/hp_block",
    method: "post",
    data
  });
}

// 提交 栏目：目的地参团 - 表单
export function searchHp(data) {
  return request({
    url: `/search/hp_search`,
    method: "post",
    data
  });
}

// 获取 频道下拉菜单 - 总览
export function regionAll() {
  return request({
    url: "/home_page/region_all",
    method: "get",
  });
}

// 频道下拉菜单 - 东欧
export function regionEe() {
  return request({
    url: "/home_page/region_ee",
    method: "get",
  });
}

// 获取 频道下拉菜单 - 西欧
export function regionWe() {
  return request({
    url: "/home_page/region_we",
    method: "get",
  });
}

// 获取 频道下拉菜单 - 南欧
export function regionSe() {
  return request({
    url: "/home_page/region_se",
    method: "get",
  });
}

// 获取 频道下拉菜单 - 北欧
export function regionNe() {
  return request({
    url: "/home_page/region_ne",
    method: "get",
  });
}

// 获取 频道下拉菜单 - 英国
export function regionEng() {
  return request({
    url: "/home_page/region_eng",
    method: "get",
  });
}

// 获取 线路检索 - 条件 - 国家
export function itemCountry(data) {
  return request({
    url: "/search/item_country",
    method: "post",
    data
  });
}

// 获取 线路检索 - 条件 - 天数
export function itemDays() {
  return request({
    url: "/search/item_days",
    method: "get",
  });
}


// 获取 线路检索 - 条件 - 城市
export function itemCity() {
  return request({
    url: "travel_city/search",
    method: "get",
  });
}


// 获取 线路检索 - 条件 - 城市-线路城市（sy-add-20201124）
export function itemRouteCity() {
  return request({
    url: "travel_city/search_is_route",
    method: "get",
  });
}


// 编辑 条件项目 交换顺序
export function itemSwap(data) {
  return request({
    url: "/search/item_swap",
    method: "post",
    data
  });
}

// 获取 线路检索-根据条件
export function routeByCondition(data) {
  return request({
    url: "/search/do",
    method: "post",
    data
  });
}

// 获取 线路检索-根据线路编号
export function travelRouteDetail({
  route_bn
}) {
  return request({
    url: `/travel_route/${route_bn}/detail`,
    method: "get",
  });
}


// 查询线路图片
export function travelRouteImg(data) {
  return request({
    url: `/travel_route_img/search_by`,
    method: "post",
    data
  });
}




// 查询线路详情分类 散拼线路
export function routeDetailDict({
  route_id
}) {
  return request({
    url: `/route_detail_dict/detail_type/${route_id}`,
    method: "get",
  });
}

// 根据线路 查询线路详情
export function routeDetailByRouteId({
  route_id
}) {
  return request({
    url: `/route_detail/search_all/${route_id}`,
    method: "get",
  });
}

// 根据线路详情类别 查询线路详情
export function routeDetailSearchType(data) {
  return request({
    url: `/route_detail/search_type`,
    method: "post",
    data
  });
}

// 查询线路价格
export function routePrice(data) {
  return request({
    url: `/route_price/search_by`,
    method: "post",
    data
  });
}

// 根据线路 查询线路开团日期
export function travelRouteDate({
  route_id
}) {
  return request({
    url: `/travel_route_date/search/${route_id}`,
    method: "get",
  });
}

// 车导产品 - 按条件查询
export function orderCarProductSearch(data) {
  return request({
    url: `/order_car_product/search_by`,
    method: "post",
    data
  });
}

// 车导车型 - 按条件查询
export function orderCarTypeSearch(data) {
  return request({
    url: `/order_car_type/search_by`,
    method: "post",
    data
  });
}

// 车导定单 - 按条件查询
export function orderCarBillSearch(data) {
  return request({
    url: `/order_car_bill/search_by`,
    method: "post",
    data
  });
}

// 门户车导列表 - 按条件查询
export function searchCar(data) {
  return request({
    url: `/search/car`,
    method: "post",
    data
  });
}

// 车导城市 - 按条件查询
export function orderCarCitySearch() {
  return request({
    url: `/order_car_city/search`,
    method: "get",
  });
}

// 获取线路收藏
export function userCarSearch(data) {
  return request({
    url: `/user_collection_car/search_by`,
    method: "post",
    data: data
  });
}



// 收藏线路 - 按条件查询
export function userRouteSearch(data) {
  return request({
    url: `/user_collection_route/search_by`,
    method: "post",
    data: data
  });
}

// 用户注册
export function clientRegister(data) {
  return request({
    url: `/client/register`,
    method: "post",
    data: data
  });
}

// 用户登录
export function clientToken(data) {
  return request({
    url: `/token`,
    method: "post",
    data: data
  });
}

// 收藏线路 新增
export function userCollectionRouteInsert(data) {
  return request({
    url: `/user_collection_route/insert`,
    method: "post",
    data: data
  });
}


// 获取静态页
export function staticPageSearch() {
  return request({
    url: `/static_page/search`,
    method: "get"
  });
}

// 登录
export function login(data) {
  return request({
    url: "/vue-element-admin/user/login",
    method: "post",
    data
  });
}

// 标签明细检索 - 标签下所有
export function routeTagDetailSearch({
  tagid
}) {
  return request({
    url: `/route_tag_detail/search/${tagid}`,
    method: "get",
  });
}

// 标签明细检索 - 标签下所有
export function routeTagSearch({
  tagid
}) {
  return request({
    url: `/route_tag/search/${tagid}`,
    method: "get",
  });
}

// 静态页检索 - 根据类别
export function searchByType({
  type_bn
}) {
  return request({
    url: `/static_page/search_by_type/${type_bn}`,
    method: "get",
  });
}

// 门户音频播放
export function musicPlaySet() {
  return request({
    url: `/music/play_set`,
    method: "get",
  });
}

// 查询 国家 所有
export function getAllCountry() {
  return request({
    url: `/travel_country/search`,
    method: "get",
  });
}

// 查询 国家 所有
export function getCountryByRegion(data) {
  return request({
    url: `/travel_country/search_by`,
    method: "POST",
    data
  });
}

// 获取 线路检索 - 条件 - 开始城市
export function getCityByCountryId(data) {
  return request({
    url: `/travel_city/search_by`,
    method: "post",
    data
  });
}

// 1.1. 私密定制 新增
export function postCustomization(data) {
  return request({
    url: `/prv_customization/insert`,
    method: "post",
    data
  });
}

// 车导定单 新增
export function carOrderInsert(data) {
  return request({
    url: `/order_car_bill/insert`,
    method: "post",
    data
  });
}

// 提交订单接口/route_order/insert
export function orderInsert(data) {
  return request({
    url: `/route_order/insert`,
    method: "post",
    data: data
  });
}

// 通过token获取用户信息
export function getUserInfoByToken(data) {
  return request({
    url: `/token/secret`,
    method: "post",
    data: data
  });
}

// 修改昵称
export function editInfo(data) {
  return request({
    url: `/user_manager/edit`,
    method: "post",
    data: data
  });
}

// 修改密码
export function editPassword(data) {
  return request({
    url: `/user_manager/update_password`,
    method: "post",
    data: data
  });
}

// 查询线路订单
export function routeOrderSearchBy(data) {
  return request({
    url: `/route_order/search_by`,
    method: "post",
    data: data
  });
}

// 查询车导订单
export function carOrderSearchBy(data) {
  return request({
    url: `/order_car_bill/search_by`,
    method: "post",
    data: data
  });
}


// 通过id 查询线路订单
export function orderSearchById(id) {
  return request({
    url: `/route_order/search/${id}`,
    method: "get",
  });
}


// 获取头部信息
export function getHeaderInfo() {
  return request({
    url: `/home_page/page_header`,
    method: "get",
  });
}

// 获取底部信息
export function getFooterInfo() {
  return request({
    url: `/home_page/page_footer`,
    method: "get",
  });
}

// 获取右侧信息
export function getRightInfo() {
  return request({
    url: `/home_page/page_info`,
    method: "get",
  });
}


// 目的地参团图片获取
export function getDestinationInfo() {
  return request({
    url: `/home_page/destination`,
    method: "get",
  });
}


// 获取酒店类型
export function getHotelType() {
  return request({
    url: `/prv_customization/hotel`,
    method: "get",
  });
}

// 获取私密定制
export function getPrivateCustomInfo() {
  return request({
    url: `/prv_customization/rec`,
    method: "get",
  });
}


// 获取特殊需求
export function get_sp_demand() {
  return request({
    url: `/order_car_product/sp_demand`,
    method: "get",
  });
}

// 线路价格获取
export function routePriceByRouteBn(data) {
  return request({
    url: `/travel_route/info`,
    method: "post",
    data: data
  });
}


// 预定线路接口
export function travel_route_reserve_insert(data) {
  return request({
    url: `/travel_route_reserve/insert`,
    method: "post",
    data: data
  });
}


// 查询预定线路订单
export function travel_route_reserve_search_by(data) {
  return request({
    url: `/travel_route_reserve/search_by`,
    method: "post",
    data: data
  });
}


// 增加定制线路订单
export function cus_route_order_insert(data) {
  return request({
    url: `/cus_route_order/insert`,
    method: "post",
    data: data
  });
}

// 查询定制线路订单
export function cus_route_order_search_by(data) {
  return request({
    url: `/cus_route_order/search_by`,
    method: "post",
    data: data
  });
}

// 编辑预定线路
export function travel_route_reserve_edit(data) {
  return request({
    url: `/travel_route_reserve/edit`,
    method: "post",
    data: data
  });
}


// 编辑定制线路
export function cus_route_order_edit(data) {
  return request({
    url: `/cus_route_order/edit`,
    method: "post",
    data: data
  });
}
