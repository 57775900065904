import moment from 'moment'
let orgnValue = val => {
  if (val === "1") {
    return "理财经理";
  } else if (val === "2") {
    return "网点";
  } else if (val === "3") {
    return "行社";
  }
  return "";
};

// 时间格式化
let timeFormatYYYYMMDD = val => {
  let resultValue = moment(val)
    // .subtract(1, "days")
    .format('YYYY-MM-DD')
  return resultValue
};

  export {
    orgnValue,
    timeFormatYYYYMMDD
  }
