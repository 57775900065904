let imgHost = '';
let musicHost = '';

switch (process.env.NODE_ENV) {
  case 'development':
    // imgHost = 'http://8.210.150.246:2001/v1/travel_route_img/view/';
    // musicHost = 'http://8.210.150.246:2001/v1/music/get_full_filename/';
    imgHost = 'https://www.youbianouzhou.com/v1/travel_route_img/view/';
    musicHost = 'https://www.youbianouzhou.com/v1/music/get_full_filename/';
    break;
  case 'production':
    // imgHost = 'http://8.210.150.246:2001/v1/travel_route_img/view/';
    // musicHost = 'http://8.210.150.246:2001/v1/music/get_full_filename/';
    imgHost = 'https://www.youbianouzhou.com/v1/travel_route_img/view/';
    musicHost = 'https://www.youbianouzhou.com/v1/music/get_full_filename/';
    break;
  default:
}

export const imgBaseURL = `${imgHost}`;
export const musicBaseURL = `${musicHost}`;

