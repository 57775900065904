<template>
  <div class="main-box main-box-home">
    <div class="main">
      <div class="box1">
        <el-image :src="require('@/assets/index/logo.png')"></el-image>
      </div>

      <div class="box2">
        <el-row :gutter="10">
          <el-col :span="10">
            <el-row :gutter="5">
              <el-col :span="6">{{ footerInfo.e_tel1_titel }}</el-col>

              <el-col :span="18">{{ footerInfo.e_tel11 }}</el-col>
            </el-row>

            <el-row :gutter="5">
              <el-col :span="18" :offset="6">{{ footerInfo.e_tel12 }}</el-col>
            </el-row>

            <el-row :gutter="5" style="margin-top: 20px">
              <el-col :span="6">{{ footerInfo.e_tel3_titel }}</el-col>

              <el-col :span="18">{{ footerInfo.e_tel31 }}</el-col>
            </el-row>

            <el-row :gutter="5">
              <el-col :span="18" :offset="6">{{ footerInfo.e_tel32 }}</el-col>
            </el-row>
          </el-col>

          <el-col :span="10">
            <el-row :gutter="5">
              <el-col :span="6">{{ footerInfo.e_tel2_titel }}</el-col>

              <el-col :span="18">{{ footerInfo.e_tel21 }}</el-col>
            </el-row>

            <el-row :gutter="5">
              <el-col :span="18" :offset="6">{{ footerInfo.e_tel22 }}</el-col>
            </el-row>

            <el-row :gutter="5" style="margin-top: 20px">
              <el-col :span="6">Email</el-col>

              <el-col :span="18">{{ footerInfo.e_email1 }}</el-col>
            </el-row>

            <el-row :gutter="5">
              <el-col :span="18" :offset="6">{{ footerInfo.e_email2 }}</el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>

      <div class="box3">
        <el-row :gutter="60">
          <el-col :span="8">
            <div class="box3-1">
              <el-image :src="footerInfo.e_img01"></el-image>

              <div>{{ footerInfo.e_img01_title }}</div>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="box3-1">
              <el-image :src="footerInfo.e_img02"></el-image>

              <div>{{ footerInfo.e_img02_title }}</div>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="box3-1">
              <el-image :src="footerInfo.e_img03"></el-image>

              <div>{{ footerInfo.e_img03_title }}</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { getFooterInfo } from '@/api/index';
export default {
  name: 'FootInfo',
  data() {
    return { footerInfo: {} };
  },
  created() {
    this.getFooterInfoFunc();
  },
  methods: {
    getFooterInfoFunc() {
      getFooterInfo()
        .then((res) => {
          this.footerInfo = res;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
.main-box-home .el-image {
  padding: 20px;
}
</style>

<style lang="scss" scoped>
.main-box {
  width: 100%;
  background: url('../assets/index/foot-bg.png') no-repeat;
  height: 180px;
  display: flex;
  justify-content: center;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;

  :deep(.el-image) {
    padding: 20px;
  }
  .main {
    font-size: 16px;
    color: #ffffff;
    display: flex;
    width: 1200px;
    .box1 {
      width: 15%
    }
    .box2 {
      width: 50%;
      margin: 40px 0 0 10px;
    }
    .box3 {
      .box3-1 {
        width: 95px;
        height: 95px;
        text-align: center;
        margin-top: 26px;
      }
    }
  }
}
</style>
