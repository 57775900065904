import {
  baseDicCurrency,
  baseDicPayStatus,
  baseDicRegular,
  baseDicJoinType,
  baseDicServiceLanguage,
  baseDicStaticPageType,
  baseDicCarType,
  baseDicTagType,
} from '@/api/index';

const getConfigBaseDicCurrency = function () {
  return baseDicCurrency()
    .then(data => ({ data }))
    .catch(error => ({ error }));
};

const getConfigBaseDicPayStatus = function () {
  return baseDicPayStatus()
    .then(data => ({ data }))
    .catch(error => ({ error }));
};

const getConfigBaseDicRegular = function () {
  return baseDicRegular()
    .then(data => ({ data }))
    .catch(error => ({ error }));
};

const getConfigBaseDicJoinType = function () {
  return baseDicJoinType()
    .then(data => ({ data }))
    .catch(error => ({ error }));
};

const getConfigBaseDicServiceLanguage = function () {
  return baseDicServiceLanguage()
    .then(data => ({ data }))
    .catch(error => ({ error }));
};

const getConfigBaseDicStaticPageType = function () {
  return baseDicStaticPageType()
    .then(data => ({ data }))
    .catch(error => ({ error }));
};

const getConfigBaseDicCarType = function () {
  return baseDicCarType()
    .then(data => ({ data }))
    .catch(error => ({ error }));
};

const getConfigBaseDicTagType = function () {
  return baseDicTagType()
    .then(data => ({ data }))
    .catch(error => ({ error }));
};

export {
  getConfigBaseDicCurrency,
  getConfigBaseDicPayStatus,
  getConfigBaseDicRegular,
  getConfigBaseDicJoinType,
  getConfigBaseDicServiceLanguage,
  getConfigBaseDicStaticPageType,
  getConfigBaseDicCarType,
  getConfigBaseDicTagType,
};
