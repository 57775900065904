import Vue from 'vue';
import Router from 'vue-router';
import Store from '@/store'

Vue.use(Router);

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

let router = new Router({
  mode: 'hash',
  routes: [{
      path: '/',
      redirect: '/home'
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/pages/home/index.vue'),
    },
    {
      path: '/travel-route/list',
      name: 'travelRouteList',
      component: () => import('@/pages/travel-route/list.vue'),
    },
    {
      path: '/travel-route/detail',
      name: 'travelRouteDetail',
      component: () => import('@/pages/travel-route/detail.vue'),
    },
    {
      path: '/travel-route/order',
      name: 'travelOrderList',
      component: () => import('@/pages/travel-route/order.vue'),
    },
    {
      path: '/rent-car/list',
      name: 'rentCarList',
      component: () => import('@/pages/rent-car/list.vue'),
    },
    {
      path: '/rent-car/detail',
      name: 'rentCarDetail',
      component: () => import('@/pages/rent-car/detail.vue'),
    },
    {
      path: '/rent-car/order',
      name: 'rentCarOrder',
      component: () => import('@/pages/rent-car/order.vue'),
    },
    {
      path: '/login/login',
      name: 'loginLogin',
      component: () => import('@/pages/login/login.vue'),
    },
    {
      path: '/login/register',
      name: 'loginRegister',
      component: () => import('@/pages/login/register.vue'),
    },
    {
      path: '/login/forget-password',
      name: 'loginForgetPassword',
      component: () => import('@/pages/login/forget-password.vue'),
    },
    {
      path: '/personal-center/index',
      name: 'personalCenterIndex',
      component: () => import('@/pages/personal-center/index.vue'),
    },
    {
      path: '/about-info/index',
      name: 'aboutInfoIndex',
      component: () => import('@/pages/about-info/index.vue'),
    },
    {
      path: '/private-custom/index',
      name: 'privateCustomIndex',
      component: () => import('@/pages/private-custom/index.vue'),
    },
    {
      path: '/business-charter/index',
      name: 'businessCharterIndex',
      component: () => import('@/pages/business-charter/index.vue'),
    },
    {
      path: '/featured-tickets/index',
      name: 'featuredTicketsIndex',
      component: () => import('@/pages/featured-tickets/index.vue'),
    },
    {
      path: '/travel-sharing/index',
      name: 'travelSharingIndex',
      component: () => import('@/pages/travel-sharing/index.vue'),
    },
    {
      path: '/vip-security/index',
      name: 'vipSecurityIndex',
      component: () => import('@/pages/vip-security/index.vue'),
    },
    {
      path: '/protocol/index',
      name: 'ProtocolIndex',
      component: () => import('@/pages/protocol/index.vue'),
    },
    {
      path: '/comm/HtmlPanel',
      name: 'HtmlPanel',
      component: () => import('@/pages/comm/HtmlPanel.vue'),
    },
  ]
});
var loginPages = [
  '/personal-center/index'
]
router.beforeEach((to, from, next) => {
  if (!Store.state.home.token && loginPages.indexOf(to.path) > -1 ) {
    next('/')
  } else {
    next()
  }
})

export default router;
