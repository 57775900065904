<template>
  <div class="rightPanel-container">
    <div class="rightPanel-boxs">
      <div class="rightPanel-box" @click="addNet()">
        <img src="@/assets/index/rightPanel-collection@2x.png" height="40" width="40" alt="" />
        <label>收藏</label>
      </div>
      <a target="_blank" :href="`http://wpa.qq.com/msgrd?v=3&uin=${rightInfo.e_qq}&site=qq&menu=yes`">
        <div class="rightPanel-box">
          <img src="@/assets/index/rightpanel-feedback@2x.png" height="40" width="40" alt="客服" title="客服" />
          <label>客服</label>
        </div>
      </a>
      <div class="rightPanel-box" v-popover:popover2>
        <img src="@/assets/index/rightpanel-service@2x.png" height="40" width="40" alt="" />
        <label>反馈</label>
      </div>
      <div class="rightPanel-box" v-popover:popover1>
        <el-image :src="require('@/assets/index/rightpanel-wechat@2x.png')"></el-image>
        <label>微信</label>
      </div>
      <el-popover ref="popover2" placement="left" width="504" height="393" trigger="click">
        <div class="feedback">
          <div class="title">意见反馈</div>
          <div class="nav">
            <div :class="['nav-item', form.feedback_type === '1' ? 'active' : '']" @click="tabChange('1')">
              订购问题
            </div>
            <div :class="['nav-item', form.feedback_type === '2' ? 'active' : '']" @click="tabChange('2')">
              页面出错
            </div>
            <div :class="['nav-item', form.feedback_type === '3' ? 'active' : '']" @click="tabChange('3')">
              意见建议
            </div>
            <div :class="['nav-item', form.feedback_type === '4' ? 'active' : '']" @click="tabChange('4')">
              其他
            </div>
          </div>
          <el-form ref="form" :rules="rules" :model="form">
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item prop="feedback_info">
                  <el-input placeholder="备注您的特殊要求" :rows="7" v-model="form.feedback_info" type="textarea">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item prop="feedback_user">
                  <el-input placeholder="先生/女士" v-model="form.feedback_user">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="18">
                <el-form-item prop="feedback_tel">
                  <el-input placeholder="联系方式（QQ/微信）" v-model="form.feedback_tel">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="4">
                <el-button class="el-but" type="primary" @click="submitForm('form')">提交反馈</el-button>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-popover>
      <el-popover ref="popover1" placement="left" width="150" trigger="hover">
        <el-image :src="rightInfo.e_wx_img"></el-image>
      </el-popover>
    </div>
  </div>
</template>

<script>
import { supportInfo, feedbackInsert, getRightInfo } from '@/api/index'

export default {
  name: 'RightPanel',
  data() {
    return {
      obj: {
        qq: '',
        tel: '',
      },
      form: {
        feedback_type: '1',
        feedback_info: '', // 意见
        feedback_user: '', // 姓名
        feedback_tel: '', // 联系方式
        feedback_status: '0',
      },
      rules: {
        feedback_info: [{ required: true, message: '请输入意见', trigger: 'blur' }],
        feedback_user: [{ required: true, message: '请输入出行姓名', trigger: 'blur' }],
        feedback_tel: [{ required: true, message: '请输入联系方式', trigger: 'blur' }],
      },
      rightInfo: {},
      showPopover: false
    }
  },
  created() {
    this.getRightInfoFunc()
    supportInfo()
      .then((response) => {
        this.obj = response.data[0]
      })
      .catch((err) => {
        console.log(err)
      })
  },
  methods: {
    getRightInfoFunc() {
      getRightInfo()
        .then((res) => {
          this.rightInfo = res
        })
        .catch((err) => {
          console.log(err)
        })
    },
    tabChange(index) {
      this.form.feedback_type = index
    },
    submitForm(form) {
      console.log()
      this.$refs[form].validate((valid) => {
        if (valid) {
          feedbackInsert(this[form]).then((res) => {
            if (res.code === 200) {
              this.$refs[form].resetFields()
              this.$message({
                message: res.message,
                type: 'success',
              })
            } else {
              this.$message({
                message: res.message,
                type: 'error',
              })
            }
            this.$refs['popover2'].doClose()
          })
        } else {
          return false
        }
      })
    },
    addNet(obj, opts) {
      var _t, _u;
      if(typeof opts != 'object'){
          _t = document.title;
          _u = location.href;
      }else{
          _t = opts.title || document.title;
          _u = opts.url || location.href;
      }
      try{
          window.external.addFavorite(_u, _t);
      }catch(e){
          if(window.sidebar){
              obj.href = _u;
              obj.title = _t;
              obj.rel = 'sidebar';
          }else{
              alert('抱歉，您所使用的浏览器无法完成此操作。\n\n请使用 Ctrl + D 将本页加入收藏夹！');
          }
      }
    }
  },
}
</script>

<style lang="scss">
.rightPanel-container {
  color: rgba(255, 255, 255, 1);

  .rightPanel-boxs {
    width: 53px;
    background: rgba(0, 0, 0, 1);
    border-radius: 14px 2px 2px 14px;
    position: fixed;
    top: 254px;
    right: 0;
    flex-direction: column;

    .rightPanel-box {
      cursor: pointer;
      padding: 16px 8px;

      label {
        text-align: justify;
        margin-left: 4px;
      }
    }
  }
}
.feedback {
  .title {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 55px;
  }
  .nav {
    padding-left: 10px;
    height: 42px;
    margin-bottom: 20px;
    &-item {
      display: inline-block;
      vertical-align: top;
      margin-right: 11px;
      width: 107px;
      height: 33px;
      background: rgba(246, 244, 244, 1);
      border-radius: 4px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 33px;
      text-align: center;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        width: 108px;
        height: 42px;
        color: #fff;
        background: url('../assets/index/icon-045.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
</style>
