import { Message } from 'element-ui';
import {
  getConfigBaseDicCurrency,
  getConfigBaseDicPayStatus,
  getConfigBaseDicRegular,
  getConfigBaseDicJoinType,
  getConfigBaseDicServiceLanguage,
  getConfigBaseDicStaticPageType,
  getConfigBaseDicCarType,
  getConfigBaseDicTagType,
 } from '@/utils/getConfig';
const state = {
  baseDicCurrencyList: [],
  baseDicPayStatusList: [],
  baseDicRegularList: [],
  baseDicJoinTypeList: [],
  baseDicServiceLanguageList: [],
  baseDicStaticHtmlList: [],
  baseDicCarTypeList: [],
  baseDicTagTypeList: [],
};

const mutations = {
  SET_Currency: (state, payload) => {
    state.baseDicCurrencyList = payload;
  },
  SET_PayStatus: (state, payload) => {
    state.baseDicPayStatusList = payload;
  },
  SET_Regular: (state, payload) => {
    state.baseDicRegularList = payload;
  },
  SET_JoinType: (state, payload) => {
    state.baseDicJoinTypeList = payload;
  },
  SET_ServiceLanguage: (state, payload) => {
    state.baseDicServiceLanguageList = payload;
  },
  SET_StaticHtml: (state, payload) => {
    state.baseDicStaticHtmlList = payload;
  },
  SET_CarType: (state, payload) => {
    state.baseDicCarTypeList = payload;
  },
  SET_TagType: (state, payload) => {
    state.baseDicTagTypeList = payload;
  },
};
const actions = {
  async setCurrency({commit}){
    const { data: { data: data = [] }, error: error } = await getConfigBaseDicCurrency();
    commit('SET_Currency',data);
    if (error) {
      console.error(error);
      Message.error('获取)配置失败');
    }
  },
  async setPayStatus({commit}){
    const { data: { data: data = [] }, error: error } = await getConfigBaseDicPayStatus();
    commit('SET_PayStatus',data);
    if (error) {
      console.error(error);
      Message.error('获取)配置失败');
    }
  },
  async setRegular({commit}){
    const { data: { data: data = [] }, error: error } = await getConfigBaseDicRegular();
    commit('SET_Regular',data);
    if (error) {
      console.error(error);
      Message.error('获取)配置失败');
    }
  },
  async setJoinType({commit}){
    const { data: { data: data = [] }, error: error } = await getConfigBaseDicJoinType();
    commit('SET_JoinType',data);
    if (error) {
      console.error(error);
      Message.error('获取)配置失败');
    }
  },
  async setServiceLanguage({commit}){
    const { data: { data: data = [] }, error: error } = await getConfigBaseDicServiceLanguage();
    commit('SET_ServiceLanguage',data);
    if (error) {
      console.error(error);
      Message.error('获取)配置失败');
    }
  },
  async setStaticHtml({commit}){
    const { data: { data: data = [] }, error: error } = await getConfigBaseDicStaticPageType();
    commit('SET_StaticHtml',data);
    if (error) {
      console.error(error);
      Message.error('获取)配置失败');
    }
  },
  async setCarType({commit}){
    const { data: { data: data = [] }, error: error } = await getConfigBaseDicCarType();
    commit('SET_CarType',data);
    if (error) {
      console.error(error);
      Message.error('获取)配置失败');
    }
  },
  async setTagType({commit}){
    const { data: { data: data = [] }, error: error } = await getConfigBaseDicTagType();
    commit('SET_TagType',data);
    if (error) {
      console.error(error);
      Message.error('获取)配置失败');
    }
  },
};


export default {
  state,
  mutations,
  actions
};

