<template>
  <div class="baby">
    <div class="main">
      <el-menu
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        background-color="#6F0F95"
        text-color="#fff"
        active-text-color="#F6C382"
      >
        <div
          :is="menuType(item)"
          v-for="item in menusList"
          :key="item.id"
          :index="item.id"
        >
          <template slot="title" v-if="menuType(item) === 'el-submenu'">
            <div v-if="item.disp_no === '70'" slot="title">
              {{ item.title }}
            </div>
            <div v-else slot="title" @click="handleSelect(item.id)">
              {{ item.title }}
            </div>
          </template>
          <template v-if="menuType(item) === 'el-menu-item'">
            {{ item.title }}
          </template>
          <div
            class="hover-wrap"
            v-if="menuType(item) === 'el-submenu' && item.disp_no === '70'"
          >
            <div
              class="hover-wrap-item"
              v-for="reg in regionAllList"
              :key="reg.region_id"
            >
              <el-menu-item
                class="hover-wrap-item-left"
                @click="goWhere(reg, {})"
                >{{ reg.region_nm }}</el-menu-item
              >
              <div class="hover-wrap-item-right">
                <el-menu-item
                  class="hover-wrap-item-right-item"
                  v-for="cou in reg.country"
                  :key="cou.country_bn"
                  @click="goWhere(reg, cou)"
                  :index="`${item.disp_no}-${reg.region_id}-${cou.id}`"
                  >{{ cou.country_nm }}</el-menu-item
                >
              </div>
            </div>
          </div>
          <div
            class="hover-wrap-other"
            v-if="menuType(item) === 'el-submenu' && item.disp_no !== '70'"
          >
            <div
              class="hover-wrap-other-item"
              v-for="reg in regionType(item)"
              :key="reg.region_id"
            >
              <div class="hover-wrap-other-item-left">
                <el-menu-item
                  class="hover-wrap-other-item-left-item"
                  v-for="cou in reg.country"
                  :key="cou.country_bn"
                  @click="goWhere(reg, cou, '2')"
                  :index="`${item.disp_no}-${reg.region_id}-${cou.id}`"
                  >{{ cou.country_nm }}</el-menu-item
                >
              </div>
              <div class="hover-wrap-other-item-m"></div>
              <div class="hover-wrap-other-item-right">
                <div class="hover-wrap-other-item-right-title">
                  OUR RECOMMENDATION
                </div>
                <div v-if="item.disp_no === '80'">
                  <div
                    class="hover-wrap-other-item-right-item"
                    v-for="(aa, index) in adEeList"
                    :key="index"
                  >
                    <router-link @click="goImage(aa.route_bn, item.id)"
                      :to="{
                        path: '/travel-route/detail',
                        query: { route_bn: aa.route_bn, region_id: item.id },
                      }"
                      v-if="index < 2"
                    >
                      <el-image class="icon" :src="aa.ad_img" alt></el-image>
                      <span class="text">{{ aa.ad_title }}</span>
                    </router-link>
                  </div>
                </div>
                <div v-if="item.disp_no === '90'">
                  <div
                    class="hover-wrap-other-item-right-item"
                    v-for="(bb, index) in adWeList"
                    :key="index"
                  >
                    <router-link
                      :to="{
                        path: '/travel-route/detail',
                        query: { route_bn: bb.route_bn, region_id: item.id },
                      }"
                      v-if="index < 2"
                    >
                      <el-image class="icon" :src="bb.ad_img" alt></el-image>
                      <span class="text">{{ bb.ad_title }}</span>
                    </router-link>
                  </div>
                </div>
                <div v-if="item.disp_no === '100'">
                  <div
                    class="hover-wrap-other-item-right-item"
                    v-for="(cc, index) in adSeList"
                    :key="index"
                  >
                    <router-link
                      :to="{
                        path: '/travel-route/detail',
                        query: { route_bn: cc.route_bn, region_id: item.id },
                      }"
                      v-if="index < 2"
                    >
                      <el-image class="icon" :src="cc.ad_img" alt></el-image>
                      <span class="text">{{ cc.ad_title }}</span>
                    </router-link>
                  </div>
                </div>
                <div v-if="item.disp_no === '110'">
                  <div
                    class="hover-wrap-other-item-right-item"
                    v-for="(dd, index) in adNeList"
                    :key="index"
                  >
                    <router-link
                      :to="{
                        path: '/travel-route/detail',
                        query: { route_bn: dd.route_bn, region_id: item.id },
                      }"
                      v-if="index < 2"
                    >
                      <el-image class="icon" :src="dd.ad_img" alt></el-image>
                      <span class="text">{{ dd.ad_title }}</span>
                    </router-link>
                  </div>
                </div>
                <div v-if="item.disp_no === '120'">
                  <div
                    class="hover-wrap-other-item-right-item"
                    v-for="(ee, index) in adEngList"
                    :key="index"
                  >
                    <router-link
                      :to="{
                        path: '/travel-route/detail',
                        query: { route_bn: ee.route_bn, region_id: item.id },
                      }"
                      v-if="index < 2"
                    >
                      <el-image class="icon" :src="ee.ad_img" alt></el-image>
                      <span class="text">{{ ee.ad_title }}</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-menu>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import {
  channels,
  regionAll,
  regionEe,
  regionWe,
  regionSe,
  regionNe,
  regionEng,
  routeTagDetailSearch,
} from '@/api/index';

export default {
  name: 'HeadNavMenu',
  data() {
    return {
      menusList: [],
      regionAllList: [],
      regionEeList: [],
      regionWeList: [],
      regionSeList: [],
      regionNeList: [],
      regionEngList: [],
      adEeList: [],
      adWeList: [],
      adSeList: [],
      adNeList: [],
      adEngList: [],
    };
  },
  computed: {},
  watch: {
   $route(to, from) {
    if (to.path == from.path) {
      if (to.query.route_bn != from.query.route_bn) {
        window.location.reload(); //监测到路由发生跳转时刷新一次页面
      }
    }
   }
  },
  created() {
    this.channels2();
    this.getEeAdData();
    this.getWeAdData();
    this.getSeAdData();
    this.getNeAdData();
    this.getEngAdData();
  },
  methods: {
    // 频道-东欧-广告
    getEeAdData() {
      routeTagDetailSearch({ tagid: 'tag_type_102' }).then((res) => {
        this.adEeList = res.data;
      });
    },
    // 频道-西欧-广告
    getWeAdData() {
      routeTagDetailSearch({ tagid: 'tag_type_103' }).then((res) => {
        this.adWeList = res.data;
      });
    },
    // 频道-南欧-广告
    getSeAdData() {
      routeTagDetailSearch({ tagid: 'tag_type_104' }).then((res) => {
        this.adSeList = res.data;
      });
    },
    // 频道-北欧-广告
    getNeAdData() {
      routeTagDetailSearch({ tagid: 'tag_type_105' }).then((res) => {
        this.adNeList = res.data;
      });
    },
    // 频道-英国-广告
    getEngAdData() {
      routeTagDetailSearch({ tagid: 'tag_type_106' }).then((res) => {
        this.adEngList = res.data;
      });
    },
    ...mapActions(['changSetRouteObj']),
    menuType(item) {
      const disp_no_submenu = ['70', '80', '90', '100', '110', '120'];
      let result = '';
      if (disp_no_submenu.indexOf(item.disp_no) > -1) {
        result = 'el-submenu';
      } else {
        result = 'el-menu-item';
      }
      return result;
    },
    regionType(item) {
      let result = '';
      if (item.disp_no === '80') {
        result = this.regionEeList;
      } else if (item.disp_no === '90') {
        result = this.regionWeList;
      } else if (item.disp_no === '100') {
        result = this.regionSeList;
      } else if (item.disp_no === '110') {
        result = this.regionNeList;
      } else if (item.disp_no === '120') {
        result = this.regionEngList;
      }
      return result;
    },
    channels2() {
      channels().then((response) => {
        this.menusList = response.data;
      });
      regionAll().then((response) => {
        this.regionAllList = response.country_list;
      });
      regionEe().then((response) => {
        this.regionEeList = response.country_list;
      });
      regionWe().then((response) => {
        this.regionWeList = response.country_list;
      });
      regionSe().then((response) => {
        this.regionSeList = response.country_list;
      });
      regionNe().then((response) => {
        this.regionNeList = response.country_list;
      });
      regionEng().then((response) => {
        this.regionEngList = response.country_list;
      });
    },
    handleSelect(key, keyPath) {
      console.log(111, key, keyPath);
      if (key === 'page_channels_50') {
        this.$router.push({
          path: '/rent-car/list',
        });
      } else if (key === 'page_channels_10') {
        const { href } = this.$router.resolve({
          path: '/private-custom/index',
        });
        window.open(href, '_blank');
      } else if (key === 'page_channels_20') {
        this.$router.push({
          path: '/business-charter/index',
        });
      } else if (key === 'page_channels_30') {
        this.$router.push({
          path: '/vip-security/index',
        });
      } else if (key === 'page_channels_40') {
        this.$router.push({
          path: '/featured-tickets/index',
        });
      } else if (key === 'page_channels_60') {
        this.$router.push({
          path: '/travel-sharing/index',
        });
      } else if (key === 'page_channels_80') {
        this.goWhere(
          {
            region_id: 'travel_region_01',
            region_nm: '东欧',
            route_type: '2'
          },
          {}
        );
      } else if (key === 'page_channels_90') {
        this.goWhere(
          {
            region_id: 'travel_region_02',
            region_nm: '西欧',
            route_type: '2'
          },
          {}
        );
      } else if (key === 'page_channels_100') {
        this.goWhere(
          {
            region_id: 'travel_region_03',
            region_nm: '南欧',
            route_type: '2'
          },
          {}
        );
      } else if (key === 'page_channels_110') {
        this.goWhere(
          {
            region_id: 'travel_region_04',
            region_nm: '北欧',
            route_type: '2'
          },
          {}
        );
      } else if (key === 'page_channels_1200') {
        this.goWhere(
          {
            region_id: 'travel_region_05',
            region_nm: '英国',
            route_type: '2'
          },
          {}
        );
      } else if (key === 'page_channels_70') {
        this.goWhere(
          {
            region_id: '',
            region_nm: '',
            route_type: '1'
          },
          {}
        );
      }
    },
    goWhere(item, elem, type) {
      if (type) {
        item.route_type = type;
      }
      this.changSetRouteObj({
        ...item,
        ...elem,
      });
      const { href } = this.$router.resolve({ path: '/travel-route/list' });
      window.open(href, '_blank');
    },
    goImage(route_bn, id) {
        console.log(id)
    }
  },
};
</script>

<style lang="scss" scoped>
.baby {
  display: flex;
  justify-content: center;
  background-color: #6f0f95;
  position: sticky;
  top: 0;
  z-index: 1000;

  .main {
    width: 1200px;

    .el-menu-demo {
      display: flex;
      justify-content: space-around;

      .el-menu-item,
      .el-submenu {
        border: none;
        :deep(.el-submenu__title) {
          border: none;
        }
        :deep(.el-submenu__icon-arrow) {
            margin-top: -85px;
        }
      }
    }

    :deep(.el-menu.el-menu--horizontal) {
      border: none;
      :deep(.el-submenu) {
        .el-submenu__icon-arrow {
          display: none;
        }
      }
    }
  }
}
.hover-wrap {
  margin-top: -10px;
  margin-bottom: -5px;
  width: 531px;
  background: rgba(255, 255, 255, 1);
  &-item {
    padding: 0 10px;
    display: flex;
    border-bottom: 2px solid rgba(245, 245, 245, 1);
    &-left {
      &::before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 5px;
        height: 11px;
        background: rgba(91, 9, 123, 1);
        margin: 0 3px 2px 0;
      }
      width: 70px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 36px;
      cursor: pointer;
      background: none !important;
      color: rgba(51, 51, 51, 1) !important;
      &:hover {
        color: rgb(246, 195, 130) !important;
      }
    }
    &-right {
      flex: 1;
      &-item {
        background-color: #fff !important;
        cursor: pointer;
        width: calc(100% / 5);
        display: inline-block;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(51, 51, 51, 1) !important;
        line-height: 36px;
        &:hover {
          color: rgb(246, 195, 130) !important;
        }
      }
    }
  }
}
.hover-wrap-other {
  margin-top: -10px;
  margin-bottom: -5px;
  width: 531px;
  background: #ebebf6;
  &-item {
    display: flex;
    align-items: center;
    padding: 30px;
    &-left {
      align-self: start;
      margin-right: 20px;
      :deep(li) {
        padding-left: 20px !important;
        width: 182px;
        height: 42px !important;
        line-height: 42px !important;
        background: rgba(255, 255, 255, 1) !important;
        border-top: 1px solid #e5e5e5;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(51, 51, 51, 1) !important;

        &:hover {
          color: rgb(246, 195, 130) !important;
        }
      }
    }
    &-m {
      width: 1px;
      height: 285px;
      background: rgba(173, 170, 170, 1);
    }
    &-right {
      align-self: start;
      padding-left: 20px;
      flex: 1;
      &-title {
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(111, 15, 149, 1);
        line-height: 36px;
        margin-bottom: 10px;
      }
      &-item {
        margin-bottom: 10px;
        .icon {
          width: 100%;
        }
        .text {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
        }
      }
    }
  }
}
</style>