<template>
  <div :class="{ 'full-screen': !showFootDown }">
    <head-top v-show="showHead"></head-top>
    <head-nav-menu v-show="showHead"></head-nav-menu>
    <router-view v-if="isRouterAlive" />
    <foot-down v-show="showFootDown"></foot-down>
    <foot-info v-show="showFootDown"></foot-info>
    <RightPanel v-show="showFootDown"></RightPanel>
  </div>
</template>

<script>
import HeadTop from "@/components/HeadTop";
import FootDown from "@/components/FootDown";
import FootInfo from "@/components/FootInfo";
import HeadNavMenu from "@/components/HeadNavMenu";
import RightPanel from "@/components/RightPanel";
import { mapActions } from "vuex";


export default {
  name: "App",
  provide() {
    return {
      reload: this.reload
    };
  },
  data: function() {
    return {
      isRouterAlive: true,
      showHead: true,
      showFootDown: true
    };
  },
  components: {
    HeadTop,
    HeadNavMenu,
    FootInfo,
    FootDown,
    RightPanel
  },
  created() {
    // this.setCurrency();
    // this.setPayStatus();
    // this.setRegular();
    // this.setJoinType();
    // this.setServiceLanguage();
    // this.setStaticHtml()
    // this.setCarType();
    // this.setTagType()
  },
  watch: {
    $route(to) {
      if (
        to.name == "loginLogin" ||
        to.name == "loginRegister" ||
        to.name == "loginForgetPassword"
      ) {
        this.showHead = false;
        this.showFoot = false;
      } else if (
        to.name == "privateCustomIndex" ||
        to.name == "ProtocolIndex"
      ) {
        this.showFootDown = false;
        this.showHead = false;
      } else {
        this.showFootDown = true;
        this.showHead = true;
        this.showFoot = true;
      }
    }
  },
  methods: {
    ...mapActions([
      "setCurrency",
      "setPayStatus",
      "setRegular",
      "setJoinType",
      "setServiceLanguage",
      "setStaticHtml",
      "setCarType",
      "setTagType"
    ]),
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function() {
        this.isRouterAlive = true;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.full-screen {
  height: 100%;
}
</style>
